import React from "react";
import Head from "../../components/Head.js";

import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RestaurantCard from "../../components/RestaurantCard";

import BattleOther from "../../components/BattleOther.js";

const allRecommendations = require("../recommendations.json");
const allTacoBattles = require("../battles.json");

const tacoBattlesInfo = allTacoBattles[1];

const infoForTacoA = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoA"]["restaurant"];
})[0];

const infoForTacoB = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoB"]["restaurant"];
})[0];

const TacoAVersusTacoB = () => {
  return (
    <>
      <Head
        title={`Tacos Battles: ${tacoBattlesInfo.title}`}
        description={`Austin Tacos Battles present ${tacoBattlesInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar taco-battles-node">
            <div className="primary-wrapper">
              <h1>{`Tacos Battles: ${tacoBattlesInfo.title}`}</h1>

              <div className="battle-groups-the-wrapper">
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoA.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoA.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoA.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoA.link}>
                      {tacoBattlesInfo.tacoA.restaurant}
                    </a>
                  </div>
                </div>
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoB.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoB.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoB.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoB.link}>
                      {tacoBattlesInfo.tacoB.restaurant}
                    </a>
                  </div>
                </div>
              </div>

              {/* + */}
              <h2>How the Tacos Compare</h2>
              <p>
                We first tried the "Suadero" taco, not knowing what to expect.
                As soon as you take a bit, the flavor hits you (in the best
                way). We don't know what is in their "black magic" oil, but get
                ready. The avacoda was a nice accent but definately not the main
                focus.
              </p>

              <p>
                The next day for lunch we went to Valentina's for the smoked
                brisket taco. We have had sea salt guacomole before, but not
                like this. The richness of the brisket is really what blew us
                away, while being accented by the serrano salsa. The serrano
                salsa was not much, but the right tempature to go with the other
                elements.
              </p>

              {/* + */}
              <h2>The Winner is... {tacoBattlesInfo.tacoA.nameOfTaco}</h2>
              <p>
                Brisket is taken seriously in Austin, as well as tacos... so
                this was no small task. Both these tacos had insanely rich
                flavor and honestly were darn near perfect, but interestingly in
                their own way. The confit brisket was so juicy, especially with
                the magic oil. The smoked brisket was amazing too, just a bit
                more tart, so it's more a matter of preference.
              </p>

              <h3>Judge for yourself and let us know!</h3>

              <div className="taco-the-battle-cards">
                <div className="taco-a-left">
                  <RestaurantCard data={infoForTacoA}></RestaurantCard>
                </div>
                <div className="taco-b-right">
                  <RestaurantCard data={infoForTacoB}></RestaurantCard>
                </div>
              </div>
            </div>

            <div className="right-sidebar">
              <BattleOther title={tacoBattlesInfo.title}></BattleOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TacoAVersusTacoB;
